import React from 'react'

import '../components/bootstrap.min.css';
import '../components/vendor/tether/tether.min.css';
import '../components/vendor/bootstrap/css/bootstrap.min.css';
import '../components/css/fonts/express-icons.css';
import '../components/css/card.css';

import '../components/vendor/font-awesome/css/font-awesome.min.css';
import '../components/vendor/bootstrap/css/glyphicon.css';
import '../components/vendor/cubeportfolio/css/cubeportfolio.min.css';

//import '../components/vendor/ion-icons/css/ionicons.min.css',
import '../components/vendor/owl-carousel/owl.theme.css';
import '../components/vendor/owl-carousel/owl.carousel.css';
import '../components/vendor/magnific-popup/magnific-popup.css';
import '../components/vendor/lite-tooltip/css/litetooltip.css';
import '../components/vendor/PricingTablesInspiration/css/component.css';
import '../components/vendor/PricingTablesInspiration/css/icons.css';
import '../components/vendor/PricingTablesInspiration/css/demo.css';
//Theme CSS
import '../components/css/main.css';
import '../components/css/main-shortcodes.css';
import '../components/css/header.css';
import '../components/css/form-element.css';
import '../components/css/animation.css';
import '../components/css/bg-stylish.css';
import '../components/css/blog.css';
import '../components/css/streamline-icon.css';
import '../components/css/responsive.css';
import '../components/css/utilities.css';
import '../components/css/portfolio.css';
import '../components/css/font-icons.css';
import '../components/css/skins/default.css';

//Theme Custom CSS
import '../components/css/custom.css';

import Footer from "../components/footer";
import Header from "../components/header";

const IndexPage = () => (
   <div class="wrapper">
      <Header/>
      <div class="page">
         <div class="page-inner p-none">
         <div class="page-inner p-none no-border">

<section class="section-primary main-color b-bordered pb-70">
   <div class="container">

      <div class="row">
         <div class="col-md-12">
            <h2 class="section-title text-center m-none">FAQs</h2>
         </div>
      </div>

      <hr class="invisible" />

      <div class="row justify-content-center">
         <div class="col-md-9">

            <div class="accordion v7" id="accordion6" role="tablist" aria-multiselectable="true">
               <div class="card">
                  <div class="card-header" role="tab" id="headingOne6">
                     <h5 class="mb-0">
                        <a data-toggle="collapse" data-parent="#accordion6" href="#collapseOne6" aria-expanded="true" aria-controls="collapseOne6">
                           <i class="fa fa-shower"></i>  What methods of payment do you accept?
                        </a>
                     </h5>
                  </div>

                  <div id="collapseOne6" class="collapse show" role="tabpanel" aria-labelledby="headingOne6">
                     <div class="card-block">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 5 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 5 wolf moon tempor, sunt aliqua
                     </div>
                  </div>
               </div>
               <div class="card">
                  <div class="card-header" role="tab" id="headingTwo6">
                     <h5 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" data-parent="#accordion6" href="#collapseTwo6" aria-expanded="false" aria-controls="collapseTwo6">
                           <i class="fa fa-user-circle"></i> Can I transfer a license from one computer to another?
                        </a>
                     </h5>
                  </div>
                  <div id="collapseTwo6" class="collapse" role="tabpanel" aria-labelledby="headingTwo6">
                     <div class="card-block">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 5 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 5 wolf moon tempor, sunt aliqua
                     </div>
                  </div>
               </div>
               <div class="card">
                  <div class="card-header" role="tab" id="headingThree6">
                     <h5 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" data-parent="#accordion6" href="#collapseThree6" aria-expanded="false" aria-controls="collapseThree6">
                           <i class="fa fa-television"></i>  What kind of support is provided with Express products?
                        </a>
                     </h5>
                  </div>
                  <div id="collapseThree6" class="collapse" role="tabpanel" aria-labelledby="headingThree6">
                     <div class="card-block">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 5 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 5 wolf moon tempor, sunt aliqua
                     </div>
                  </div>
               </div>
               <div class="card">
                  <div class="card-header" role="tab" id="headingThree6">
                     <h5 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" data-parent="#accordion6" href="#collapseThree6s" aria-expanded="false" aria-controls="collapseThree6s">
                           <i class="fa fa-television"></i>  How can I keep my account private?
                        </a>
                     </h5>
                  </div>
                  <div id="collapseThree6s" class="collapse" role="tabpanel" aria-labelledby="headingThree6">
                     <div class="card-block">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 5 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 5 wolf moon tempor, sunt aliqua
                     </div>
                  </div>
               </div>

               
            </div>

         </div>

      </div>
   </div>
</section>

</div>

            <Footer/>
         </div>
      </div>
   </div>
)

export default IndexPage
